<template>
  <div class="shop-detail">
    <HomeSwiper :src="src"></HomeSwiper>
    <div class="content">
      <el-card>
        <div class="show-link-content">
          <div class="link-content-left">
            <img alt="" />
            <div class="title pointer" @click="handleClick">
              {{ tableData.companyName }}
            </div>
          </div>

          <el-button type="primary" @click="handleClick">进入展台</el-button>
        </div>
      </el-card>
      <el-card class="mt10">
        <div class="detail-content">
          <div class="shop-info">
            <div class="shop-info-left">
              <img
                :src="tableData.coverUrl"
                v-if="tableData.coverUrl"
                class="image"
                alt=""
              />
              <div v-else class="text">{{ tableData.name }}</div>
            </div>
            <div class="shop-info-right">
              <div class="right-top">
                {{ tableData.name }}
              </div>
              <div class="right-bottom">
                <div class="bottom-item">
                  <div class="item-title">价格</div>
                  <div class="item-info price">{{ tableData.price }}</div>
                </div>
                <div class="bottom-item">
                  <div class="item-title">品牌</div>
                  <div class="item-info">{{ tableData.brand }}</div>
                </div>
                <div class="bottom-item">
                  <div class="item-title">规格</div>
                  <div class="item-info">{{ tableData.norms }}</div>
                </div>

                <div class="bottom-item">
                  <div class="item-title">产地/服务地区</div>
                  <div class="item-info">{{ tableData.place }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="detail-title">产品介绍</div>
        <div class="introduce-content">
          {{ tableData.introduction }}
        </div>

        <div class="footer">
          <div
            v-for="item in tableData.images"
            :key="item.id"
            class="image-box"
          >
            <img :src="item.coverUrl" alt="" />
          </div>

          <div class="video-box">
            <div class="player-box">
              <Player ref="Player"></Player>
            </div>

            <div class="banner" v-if="showBanner">
              <div v-for="item in tableData.videos" :key="item.id" class="item">
                <img
                  :src="item.coverUrl"
                  alt=""
                  @click="handlePlay(item.playUrl)"
                />
              </div>
            </div>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import HomeSwiper from "@/components/Cms/HomeSwiper";
import Player from "@/components/Cms/Player";

import { getGoods } from "@/api/cms/goods.js";

export default {
  components: {
    HomeSwiper,
    Player,
  },
  data() {
    return {
      src: "/images/banner.jpg",
      showBanner: false,
      tableData: {},
    };
  },
  methods: {
    handleClick() {
      window.open(`/cms/company/show/${this.tableData.companyId}`);
    },
    handlePlay(playUrl) {
      this.$refs.Player.play(playUrl);
    },
    getGoods() {
      getGoods(this.$route.params.id).then((res) => {
        this.tableData = res;

        this.showBanner = res.videos.length > 1;
        if (res.videos.length > 0) {
          this.$refs.Player.play(res.videos[0].playUrl);
        }
      });
    },
  },
  created() {
    this.getGoods();
  },
};
</script>

<style scoped lang="scss">
.shop-detail {
  height: 100%;
}

.content {
  padding: 30px;
  max-width: 1260px;
  margin: auto;

  .footer {
    max-width: 700px;
    margin: auto;

    .video-box {
      .banner {
        margin-top: 20px;
        .item {
          float: left;

          img {
            width: 160px;
            height: 160px;
            border: 1px solid #eff1;
            border-radius: 6px;
            cursor: pointer;
          }

          :hover {
            border: 1px solid #ff5000ee;
          }
        }
      }
    }
  }
}

.topBanner {
  width: 100%;
  height: 750px;
  object-fit: cover;
}

.detail-title {
  background-color: #f8f8f8;
  padding: 25px 20px;
  font-size: 16px;
  font-weight: 400;
}

.detail-content {
  margin-top: 20px;
}

.shop-info {
  display: flex;
  justify-content: space-between;

  .shop-info-left {
    width: 50%;
  }

  .shop-info-right {
    width: 50%;
  }

  .text {
    font-size: 50px;
    padding: 0px 20px 0 20px;
  }
}

.shop-info-right .right-top {
  line-height: 28px;
  color: #000;
  letter-spacing: 0;
  font-size: 20px;
  font-weight: 900;
}

.shop-info-right .right-bottom {
  background-color: #eeeff1;
  border-radius: 10px;
  margin-top: 30px;
  padding: 20px 30px 5px;
}

.shop-info-right .right-bottom .bottom-item {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  line-height: 33px;
}

.right-bottom .bottom-item .item-title {
  width: 25%;
}

.price {
  color: #f40;
  font-weight: 700;
  font-size: 19px;
}

.image-box {
  text-align: center;

  img {
    width: 100%;
    height: 100%;
  }
}

.image {
  max-width: 90%;
  height: 384px;
  overflow: hidden;
}

.right-bottom .bottom-item .item-info {
  font-size: 14px;
}

.introduce-content {
  padding: 20px 0 30px 0;
}

.show-link-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.show-link-content .link-content-left {
  display: flex;
  align-items: center;
}

.show-link-content .link-content-left img {
  width: 70px;
  height: 70px;
  border-radius: 10px;
}

.show-link-content .link-content-left .title {
  font-size: 20px;
  margin-left: 50px;
}

.show-link-content .link-content-right {
  background: #5a96f0;
  border-radius: 10px;
  color: #fff;
  padding: 15px 30px;
}

@media screen and (max-width: 600px) {
  .show-link-content {
    .link-content-left {
      display: flex;
      justify-content: space-between;
      align-items: center;

      img {
        width: 40px;
        height: 40px;
        border-radius: 10px;
        background-color: #0ce9f1;
      }

      .title {
        font-size: 14px;
        margin-left: 10px;
      }
    }
  }

  .shop-info {
    display: flex;
    flex-direction: column;

    .shop-info-left {
      width: 100%;

      .image {
        max-width: 100%;
        min-width: 100%;
        height: 300px;
        overflow: hidden;
        object-fit: fill;
      }

      .text {
        font-size: 14px;
        padding: 0px 20px 0 20px;
      }
    }

    .shop-info-right {
      width: 100%;

      .right-top {
        line-height: 24px;
        color: #000;
        letter-spacing: 0;
        font-size: 14px;
        font-weight: 900;
      }

      .right-bottom {
        background-color: #eeeff1;
        border-radius: 10px;
        margin-top: 30px;
        padding: 15px;

        .bottom-item {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          line-height: 33px;

          .item-title {
            width: 30%;
          }
        }
      }
    }
  }

  .detail-title {
    background-color: #f8f8f8;
    padding: 15px;
    font-size: 16px;
    font-weight: 400;
    margin-top: 20px;
  }

  .introduce-content {
    padding: 0;
    margin: 20px 0;
  }

  .image-box {
    width: 100%;
    img {
      max-width: 100%;
      min-width: 100%;
      overflow: hidden;
      object-fit: fill;
    }
  }

  .video-box {
    .banner {
      margin-top: 10px;
      .item {
        img {
          width: 60px;
          height: 60px;
        }
      }
    }
  }
}
</style>
