import request from "@/utils/request";

export function listByCompany(companyId) {
  return request({
    url: `/v1/goods/company/${companyId}`,
    method: "get",
  });
}

export function getGoods(id) {
  return request({
    url: `/v1/goods/${id}`,
    method: "get",
  });
}

export function searchGoods(data) {
  return request({
    url: `/v1/goods/search`,
    method: "get",
    params: data,
  });
}
