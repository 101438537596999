<template>
  <div>
    <div v-if="showTip">转换中,请您稍后打开.....</div>
    <div id="J_prismPlayer"></div>
  </div>
</template>

<script>
//导出aliplayer
import "aliyun-aliplayer";
// 导出样式
import "aliyun-aliplayer/dist/skins/default/aliplayer-min.css";

export default {
  data() {
    return {
      showTip: false,
      player: null,
    };
  },
  methods: {
    play(playUrl) {
      if (!playUrl) {
        this.showTip = true;
        return;
      }
      if (this.player) {
        //this.player.autoplay = true;
        this.player.loadByUrl(playUrl);
        this.player.play();
        return;
      }

      let height = this.isMobile() ? "180px" : "450px";
      this.player = new Aliplayer(
        {
          id: "J_prismPlayer",
          source: playUrl,
          width: "100%",
          height: height,
          autoplay: false,
          isLive: false,
          rePlay: false,
          // "playsinline": true,
          preload: true,
          controlBarVisibility: "hover",
          useH5Prism: true,
          encryptType: 1,
          mediaType: "video",
        },
        function (player) {
          console.log("The player is created.");
        }
      );
    },
  },
};
</script>

<style></style>
