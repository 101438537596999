<template>
  <div
    :class="isHomePage ? 'swiper-container' : 'swiper-container-page'"
    :style="{ backgroundImage: 'url(' + src + ')' }">
    <div class="logo" v-if="isHomePage">
      2024新疆农业机械博览会
      <!-- <div class="tips">Sinkiang Agricultural Machinerv Exhibition</div> -->

      <div class="time">2024年5月25日-27日 新疆国际会展中心</div>
    </div>
    <div :class="isHomePage ? 'search-container' : 'search-container-page'">
      <div class="search-box">
        <input type="text" :placeholder="placeholderText" v-model="keywordx" />
        <div class="search-btn" @click="handleSearch()">
          <img src="/images/search.png" alt="" />
        </div>
      </div>
    </div>
    <div class="swiper-pagination"></div>
    <!-- 显示分页器 -->
    <div class="swiper-button-next"></div>
    <!-- 显示向后的箭头 -->
    <div class="swiper-button-prev"></div>
    <!-- 显示向前的箭头 -->
  </div>
</template>

<script>
export default {
  props: {
    src: {
      value: "",
      type: String,
    },
    keyword: {
      value: "",
      type: String,
      default: "",
    },
    placeholderText: {
      value: "",
      type: String,
      default: "搜索",
    },
    isHomePage: {
      value: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      keywordx: this.keyword,
    };
  },
  methods: {
    handleSearch() {
      this.$emit("search", this.keywordx);
    },
  },
};
</script>

<style lang="scss" scoped>
/* 根据需要自定义样式 */
.swiper-container {
  padding-top: 20px;
  width: 100%;
  height: 450px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.swiper-container-page {
  width: 100%;
  height: 320px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-top: 120px;
}

.search-box {
  width: 600px;
  height: 80px;
  background: #fff;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.search-box input {
  width: 100%;
  background: transparent;
  border: none;
  padding-left: 30px;
  font-size: 30px;
  color: black;
  font-weight: bold;
}

.search-box .search-btn {
  width: 20%;
  height: 120px;
  color: black;
  line-height: 120px;
  text-align: center;
  background: #5c9aed;
  cursor: pointer;
}

.search-btn img {
  width: 60px;
  height: 60px;
  display: inline-block;
  margin: 10px 0;
}

.logo {
  color: #fff;
  font-size: 60px;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 20px;

  .tips {
    font-size: 30px;
    font-style: italic;
  }

  .time {
    margin-top: 30px;
    font-size: 20px;
    font-style: italic;
  }
}

.topBanner {
  width: 100%;
  height: 450px;
  object-fit: cover;
}

.search-container {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.search-container-page {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 600px) {
  .swiper-container {
    padding-top: 20px;
    width: 100%;
    height: 350px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .swiper-container-page {
    width: 100%;
    height: 180px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding-top: 65px;
  }

  .logo {
    margin-top: 10px;
    font-size: 1.4rem;

    .tips {
      font-size: 1rem;
    }
    .time {
      font-size: 0.7rem;
    }
  }

  .search-box {
    width: 80%;
    left: 10%;
    margin-left: 0;
    top: auto;
    height: 3rem;
  }
  .search-box input {
    font-size: 1rem;
  }
  .search-box .search-btn {
    width: 20%;
    height: 3rem;
    display: flex;
    justify-content: center;
  }

  .search-btn img {
    width: 1.5rem;
    height: 1.5rem;
    display: inline-block;
    margin: 10px 0;
  }
  .swiper-container,
  .topBanner {
    height: 14rem;
  }
}
</style>
